.App {
}

.gantt {
    /*height: calc(100vh - 82px);*/
    overflow: scroll;
}

.any__designer .bar {
    fill: lightpink !important;
}
.any__jacob .bar {
    fill: rebeccapurple !important;
}
.any__rafi .bar {
    fill: lightgreen !important;
}

.be__yalantis .bar {
    fill: lightcoral !important;
}

.fe__yalantis .bar {
    fill: lightblue !important;
}

.any__yalantis .bar {
    fill: lightsalmon !important;
}
